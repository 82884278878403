import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {map, Observable, switchMap} from "rxjs";
import {environment} from "../../../environments/environment";
import {Balance} from "../models/user";
import {PaginationEvent} from "../models/table";
import {ApiResponseItemsWithMeta, ApiResponseWithMeta, UnknownObject, ValueAndLabel} from "../models/api";
import {Beneficiary, BeneficiaryApi, Payment, PaymentPayload, PaymentStatus, TransferFee} from "../models/transaction";
import {Country} from "../models/country";
import {arrayToObjectWithCamelCaseKeys} from "../../helpers/maps.helper";
import {translateAndSortCountries} from "../../helpers/translation.helper";
import {TranslateService} from "@ngx-translate/core";
import {FieldType} from "../../modules/recipient/models/recipientForm";

@Injectable({
  providedIn: 'root'
})
export class PayOutService {

  private http = inject(HttpClient);
  private translateService = inject(TranslateService);

  getUserBalances(): Observable<Balance[]> {
    return this.http.get<Balance[]>(`${environment.supraApi}/v1/user/me/company/balances`);
  }

  getPayments(query : {
    pagination?: PaginationEvent,
    filter?: {
      search?: string,
      status?: PaymentStatus
    }
  }): Observable<ApiResponseWithMeta<Payment>> {
    let params = new HttpParams()
      .set('limit', query.pagination?.pageSize?.toString() || '10')
      .set('page', query.pagination?.pageIndex ? (query.pagination.pageIndex + 1).toString() : '1');

    if (query.filter) {
      Object.entries(query.filter).forEach(([key, value]) => {
        if (value) {
          params = params.set(`filter.${key}`, value.toString());
        }
      });
    }
    return this.http.get<ApiResponseWithMeta<Payment>>(`${environment.supraApi}/v2/payout/payment/user/me`, {params});
  }

  getCountries(): Observable<Country[]>{
    const params = {
      limit: 100,
      page: 1
    }
    return this.http.get<{ countries: Country[] }>(`${environment.supraApi}/v1/flows/payout/countries`, {params})
      .pipe(
        switchMap(response => translateAndSortCountries(response, this.translateService)),
        map(response => response.countries)
      );
  }

  getBeneficiaries(): Observable<ApiResponseWithMeta<Beneficiary>>{
    const params = {
      limit: 100,
      page: 1
    }
    return this.http.get<ApiResponseItemsWithMeta<BeneficiaryApi>>(`${environment.supraApi}/v1/flows/payout/beneficiaries`, {params})
      .pipe(
        map((response) => ({
          meta: response.meta,
          data: response.items.map(item => ({
            ...item,
            details: arrayToObjectWithCamelCaseKeys(item.details, 'fieldName', 'value'),
            accounts: item.accounts.map(account => ({
              id: account.id,
              ...arrayToObjectWithCamelCaseKeys(account.details, 'fieldName', 'value'),
            })),
          })),
        }))
      );
  }

  getPaymentTypes(): Observable<ValueAndLabel[]> {
    return this.http.get<Record<string, string>>(`${environment.supraApi}/v1/payout/payment/types`)
      .pipe(
        map(
          response => Object.entries(response)
            .map(([key, value]) => ({
              value: key,
              label: value
            })
          )
        )
      );
  }

  getPayoutFees(currencyId: string): Observable<TransferFee[]>{
    return this.http.get<TransferFee[]>(`${environment.supraApi}/v1/payout/currency-fee/${currencyId}`)
  }

  createPayment(payload: PaymentPayload): Observable<UnknownObject>{
    return this.http.post<UnknownObject>(`${environment.supraApi}/v1/api/payout/payment`, payload)
  }

  getPaymentById(id: string): Observable<Payment>{
    return this.http.get<Payment>(`${environment.supraApi}/v2/payout/payment/${id}`)
  }

  getFields(fieldType: FieldType, countryCode: string): Observable<{ fields: [] }>{
    return this.http.get<{ fields: []}>(`${environment.supraApi}/v1/api/fields/${fieldType}/country/${countryCode}`)
  }

  changePaymentStatus(id: string, status: PaymentStatus): Observable<UnknownObject>{
    return this.http.patch<UnknownObject>(`${environment.supraApi}/v2/payout/payment/${id}`, {
      status
    })
  }
}
