export const statusColorMap = (status: string) => {
  return {
    "COMPLETED": 'success',
    "PAID": 'success',
    "PARTIALLY-PAID": 'success',
    "PENDING": 'pending',
    "CREATED": 'pending',
    "VERIFIED": 'pending',
    "REJECTED": 'declined',
    "APPROVED": "success",
    "IN_PROGRESS": "pending"
  } [status];
}

export const arrayToObjectWithCamelCaseKeys = <T extends Record<string, unknown>>(
  array: T[],
  key: keyof T,
  value: keyof T
): Record<string, unknown> =>{
  return array.reduce((acc, item) => {
    const originalKey = String(item[key]);
    const camelCaseKey = toCamelCase(originalKey);
    acc[camelCaseKey] = item[value];
    return acc;
  }, {} as Record<string, unknown>);
}

export const toCamelCase = (key: string): string =>{
  return key
    .replace(/([-_][a-zA-Z0-9])/g, group =>
      group.toUpperCase().replace('-', '').replace('_', '')
    )
    .replace(/^[A-Z]/, group => group.toLowerCase());
}
